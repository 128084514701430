import * as React from "react"
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react"
import Link from "gatsby-link"
import ReactHtmlParser from "react-html-parser"
import { detectAsterisk } from "../../utils/common"

function isProduct(obj) {
  return obj.type === "directory" && !obj.name.includes("00_LOGO")
}

let count = 0

const AllProduits = (props) => {
  let produit_infos = {}

  const customStyles = {
    marginTop: "20px",
    marginRight: "30px",
    marginLeft: "30px",
    marginBottom: "20px",
  }

  const linkStyles = {
    color: "black",
    textDecoration: "none",
  }

  function GetProductInfosFromGraphQL(ref) {
    let infos = {}

    if (props.data.allSetemProduct.edges) {
      props.data.allSetemProduct.edges.map((prod, index) => {
        if (prod.node.hit._source.reference === ref) {
          if (prod.node.hit._source.usine) {
            infos.details = prod.node.hit._source
          }
        }
      })
    }

    return infos
  }

  /*return (
        <TableContainer>
            <Table variant='striped'>
                <TableCaption>Imperial to metric conversion factors</TableCaption>
                <Thead>
                    <Tr>
                        <Th>To convert</Th>
                        <Th>into</Th>
                        <Th isNumeric>multiply by</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr>
                        <Td><div className={"separator"}>inches</div></Td>
                        <Td><div className={"separator"}>millimetres (mm)</div></Td>
                        <Td><div className={"separator"}>isNumeric>25.4</div></Td>
                    </Tr>
                    <Tr>
                        <Td>feet</Td>
                        <Td>centimetres (cm)</Td>
                        <Td isNumeric>30.48</Td>
                    </Tr>
                    <Tr>
                        <Td>yards</Td>
                        <Td>metres (m)</Td>
                        <Td isNumeric>0.91444</Td>
                    </Tr>
                </Tbody>
                <Tfoot>
                    <Tr>
                        <Th>To convert</Th>
                        <Th>into</Th>
                        <Th isNumeric>multiply by</Th>
                    </Tr>
                </Tfoot>
            </Table>
        </TableContainer>
    )*/

  return (
    <TableContainer style={customStyles} id={"allProductsTable"}>
      <Table
        variant="striped"
        colorScheme="" /*style={{tableLayout: 'fixed'}}*/
      >
        <Thead>
          <Tr>
            <Th>N°</Th>
            <Th>CODE SETEM</Th>
            <Th>DESIGNATION USINE</Th>
            <Th>CODE USINE</Th>
            <Th>#</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.data.allProductS3.edges.map((produit, index) => {
            if (isProduct(produit.node.hit)) {
              produit_infos = GetProductInfosFromGraphQL(produit.node.hit.name)
              //console.log(produit_infos)
              return (
                <Tr>
                  <Td>
                    <div className={"separator"}>
                      <Link
                        to={`/produit/${produit.node.hit.name}`}
                        style={linkStyles}
                      >
                        {++count}
                      </Link>
                    </div>
                  </Td>
                  <Td>
                    <div className={"separator"}>
                      <Link
                        to={`/produit/${produit.node.hit.name}`}
                        style={linkStyles}
                      >
                        {produit.node.hit.name}
                      </Link>
                    </div>
                  </Td>
                  <Td>
                    <div className={"separator"}>
                      <Link
                        to={`/produit/${produit.node.hit.name}`}
                        style={linkStyles}
                      >
                        {produit_infos.details
                          ? ReactHtmlParser(
                              detectAsterisk(
                                produit_infos.details.usine.replace(
                                  "#N/A",
                                  "-"
                                ),
                                -3
                              )
                            )
                          : "-"}
                      </Link>
                    </div>
                  </Td>
                  <Td>
                    <div className={"separator"}>
                      <Link
                        to={`/produit/${produit.node.hit.name}`}
                        style={linkStyles}
                      >
                        {produit_infos.details
                          ? produit_infos.details.code.replace("#N/A", "-")
                          : "-"}
                      </Link>
                    </div>
                  </Td>
                  <Td style={{ display: "revert" }}>
                    <div className={"separator"}>
                      <Link
                        to={`/produit/${produit.node.hit.name}`}
                        style={linkStyles}
                      >
                        <img
                          src={
                            index % 2 === 0 ? "/grey_eye.png" : "/blue_eye.png"
                          }
                          style={{
                            height: "30px",
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                          alt={
                            index % 2 === 0 ? "grey_eye.png" : "blue_eye.png"
                          }
                        />
                      </Link>
                    </div>
                  </Td>
                </Tr>
              )
            }
          })}
        </Tbody>
        <center>
          {count === 0
            ? "Cette marque ne contient pas encore de produit, nous vous invitons à revenir plus tard pour plus de produits."
            : ""}
        </center>
        <Tfoot>
          <Tr>
            <Th>N°</Th>
            <Th>CODE SETEM</Th>
            <Th>DESIGNATION USINE</Th>
            <Th>CODE USINE</Th>
            <Th>#</Th>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  )
}

export default AllProduits
