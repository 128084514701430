import React from "react"
import "../styles/produits.css"
import { Footer } from "../components/footer/Footer"
import { Header } from "../components/header/Header"
import BannMarque from "../components/marque/BanniereMarque"
import AllProduits from "../components/produits/AllProduits"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

const produits = ({ data, pageContext }) => {
  //console.log(pageContext)
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {`Tous les produits de la marque ${pageContext.name_marque}`}
        </title>
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Tous les produits de la marque ${pageContext.name_marque}`}
        />

        <meta
          name="description"
          content={`Cette page contient tous les produits de la marque ${pageContext.name_marque}`}
        />

        <meta name="keywords" content={pageContext.name_marque} />

        <meta
          property="og:description"
          content={`Cette page contient tous les produits de la marque ${pageContext.name_marque}`}
        />
        <meta property="og:site_name" content="SETEM" />
      </Helmet>
      <Header />
      <BannMarque name={pageContext.name_marque} />
      <AllProduits data={data} />
      <Footer />
    </div>
  )
}

export const query = graphql`
  query ($name_marque: String) {
    allProductS3(
      sort: { fields: hit___name, order: ASC }
      filter: { hit: { brand: { eq: $name_marque } } }
    ) {
      edges {
        node {
          hit {
            name
            type
            brand
          }
        }
      }
    }

    allSetemProduct(
      filter: { hit: { _source: { brand: { eq: $name_marque } } } }
    ) {
      edges {
        node {
          key
          hit {
            _source {
              usine
              reference
              code
            }
          }
        }
      }
    }
  }
`

export default produits
