import "@fontsource/dancing-script"
import "@fontsource/open-sans"
import {graphql, useStaticQuery} from "gatsby"
import Link from "gatsby-link"
import React, {useEffect, useState} from "react"
import TestImage from "../../assets/img/no-image.jpg"
import SearchForm from "../searchForm/SearchForm"
import "./banniereMarque.css"

function generateSlug(item) {
    item = item.toLowerCase()
    item = item.split(" ").join("-")
    return item
}

function correctLogoName(marque) {
    marque = marque.toLowerCase()
    marque = marque.split(" ").join("_")
    return marque
}

function correctFolderName(marque) {
    marque = marque.toUpperCase()
    marque = marque.split(" ").join("+")
    return marque
}

function changeImageUrlToThumb(url) {
    if (url.includes(".jpg")) {
        url = url.replace(".jpg", "-thumb.jpg")
    } else if (url.includes(".png")) {
        url = url.replace(".png", "-thumb.png")
    }
    return url
}

const BannMarque = (props) => {
    const [offsetY, setOffsetY] = useState(0)
    const handleScroll = () => setOffsetY(window.pageYOffset)

    const [search, setSearch] = useState("")

    const updateSearch = (e) => {
        //console.log(e)
        setSearch(e.target.value)
        // console.log(search);
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)

        return () => window.removeEventListener("scroll", handleScroll)
    }, [])

    const bannieresearchdata = useStaticQuery(graphql`
    query {
      allWpPost(filter: { title: { eq: "homeheadpostfront" } }) {
        nodes {
          id
          title
          content
          featuredImage {
            node {
              mediaItemUrl
            }
          }
        }
      }
    }
  `)
    const banniereNodes = bannieresearchdata.allWpPost
    const marque = props.name

    let marque_logo_url = ""
    marque_logo_url =
        "https://media-setem.s3.eu-central-1.amazonaws.com/articles/" +
        correctFolderName(marque ? marque : "") +
        "/00_LOGO-ETIQUETTES/marque_logo_" +
        correctLogoName(marque ? marque : "") +
        ".png"

    return (
        <div className="bannMarqueBox">

            <div className="bannBox">
                <div className="logoMarqueBox logo-background"
                     style={{width: "auto", marginLeft: "80%", paddingRight: "1%"}}>
                    <Link to={`/${props.name ? generateSlug(props.name) : ""}`}>
                        <img
                            //src={TestImage}
                            src={changeImageUrlToThumb(marque_logo_url)}
                            className="logo-brand"
                            alt={(marque) ? marque.replaceAll("_", " ") : ""}
                        />
                    </Link>
                    {/*ReactHtmlParser(marque_image)*/}
                </div>
                <SearchForm {...{search, updateSearch, to: "/searchpage"}} />
                {/*<div className="search-contain">
                                        <form className="search-form">
                                            <input id="search-zone" className="search" type="text"
                                                   placeholder="Rechercher un produit" size="30" name="email"/>
                                            <button className="btn-search"><img class="search-img" src="/loupe.png"
                                                                                alt="img-depuis 1990"/></button>
                                        </form>
                                    </div>*/}
            </div>


        </div>
    )
}

export default BannMarque
